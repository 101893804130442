#leistungen {
  width: 100%;
  height: 100%;
  min-height: 380px;

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;

  padding: 0 5%;
  padding-top: 80px;

  div {
    width: 400px;
    min-height: 380px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    h2 {
      width: 100%;
      text-align: left;
    }
    ul {
      list-style-type: none;
      text-align: left;
      line-height: 1.6;
    }
  }
  div.right {
    padding-top: 70px;
  }
}

@media (max-width: 893px) {
  #leistungen {
    div.right {
      padding-top: 10px;
    }
  }
}
