@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;1,400&display=swap);
body{margin:0;font-family:'Montserrat', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background-color:#e6e4e4}*{box-sizing:border-box;scroll-behavior:smooth}

.App{margin:auto;text-align:center;background-color:white;box-shadow:1px 1px 50px silver;max-width:1200px}.leaflet-container{width:400px;height:350px}.white-bg-footer{background-color:white !important}#home::before{display:block;content:' ';margin-top:-285px;height:285px;visibility:hidden;pointer-events:none}

.gallerie{height:350px;width:100%;display:flex;flex-flow:column nowrap;justify-content:center;align-items:center;background-image:url(/static/media/gallery_background.a38967ca.svg);background-size:cover;background-position:bottom center;background-repeat:no-repeat;margin-bottom:20px}.gallerie .wrapper{width:100%;height:270px}.panel{display:flex;align-items:center;justify-content:center;width:450px;height:270px}.panel img{border-radius:10px;width:450px;height:270px;object-fit:cover}@media (max-width: 500px){.panel img{border-radius:3px}}@supports (-ms-ime-align: auto){.gallerie{margin-top:50px}}

#text{padding-bottom:40px}#text h1{margin:0 auto 20px auto}#text p{font-size:1.2rem;max-width:750px;margin:0 auto;text-align:center}@media (max-width: 900px){#text p{margin:0 10%}}@media (max-width: 500px){#text h1{margin:0 10% 20px 10%}#text p{font-size:1rem;margin:0 10%;text-align:left}}

#kontakt{width:100%;height:100%;min-height:480px;background-image:url(/static/media/contact_background.d7a91a08.svg);background-size:cover;background-position:top right;background-repeat:no-repeat;padding:0 5%;padding-top:80px;display:flex;flex-flow:row wrap;align-items:center;justify-content:space-around}#kontakt div.kontakt-info{width:400px;height:250px;display:flex;flex-flow:column nowrap;align-items:flex-start;justify-content:flex-start}#kontakt div.kontakt-info .adress-info{width:100%;height:100px;display:flex;align-items:flex-start;padding:0 5%}#kontakt div.kontakt-info .adress-info .adresse{display:flex;flex-flow:column nowrap;justify-content:space-between;align-items:flex-start;height:100%;padding:10px 5px}#kontakt div.kontakt-info .phone-number{width:100%;height:100px;display:flex;align-items:flex-start;padding:0 5%}#kontakt div.kontakt-info .phone-number span{display:flex;flex-flow:column nowrap;justify-content:space-between;align-items:flex-start;height:100%;padding:10px 5px}#kontakt div.karte{box-sizing:content-box;border-radius:5px;border:5px solid white;width:399px;height:348px;overflow:hidden;display:flex;align-items:center;justify-content:center;box-shadow:0px 0px 10px #464646}.kontakt-info img{padding:0 5px}

#termine{width:100%;height:100%;min-height:380px;display:flex;flex-flow:row wrap;align-items:center;justify-content:space-around;padding:0 5%;padding-top:80px}#termine div{width:400px;height:320px;display:flex;flex-flow:column nowrap;align-items:center;justify-content:flex-start}#termine div h2{width:100%;text-align:left}#termine div tbody th{font-weight:bold;text-align:left}#termine div p{width:300px;font-size:1.1rem;text-align:left;padding-top:20px}@media (max-width: 500px){#termine div p{width:100%;padding:0 5%}}

#krankenkassen{width:100%;height:100%;min-height:430px;background-image:url(/static/media/krankenkassen_background.e57a8749.svg);background-size:cover;background-position:top left;background-repeat:no-repeat;display:flex;flex-flow:row wrap;align-items:center;justify-content:space-around;padding:0 5%;padding-top:80px}#krankenkassen div{width:400px;min-height:350px;display:flex;flex-flow:column nowrap;align-items:flex-start;justify-content:flex-start}#krankenkassen div p{padding:0 5%;text-align:left}#krankenkassen div ul{list-style-type:none;text-align:left}#krankenkassen div ul li{padding-bottom:1em;line-height:1.5em}#krankenkassen div.right{padding-top:30px}

#leistungen{width:100%;height:100%;min-height:380px;display:flex;flex-flow:row wrap;align-items:center;justify-content:space-around;padding:0 5%;padding-top:80px}#leistungen div{width:400px;min-height:380px;display:flex;flex-flow:column nowrap;align-items:center;justify-content:flex-start}#leistungen div h2{width:100%;text-align:left}#leistungen div ul{list-style-type:none;text-align:left;line-height:1.6}#leistungen div.right{padding-top:70px}@media (max-width: 893px){#leistungen div.right{padding-top:10px}}

#team{width:100%;height:100%;min-height:600px;background-image:url(/static/media/team_background.701905c4.svg);background-size:cover;background-position:top center;background-repeat:no-repeat;padding:0 5%;padding-top:80px;margin:0;position:relative;top:20px;display:flex;flex-flow:column nowrap;align-items:flex-start;justify-content:center}#team h2{margin-left:5%}#team div{display:flex;flex-flow:row wrap;align-items:center;justify-content:space-around;width:100%}#team div div{max-width:400px;padding:30px 50px;display:flex;flex-flow:column nowrap;align-items:center;justify-content:flex-start;height:300px}#team div div h3{margin:10px 0}#team div div p{margin:0}#team img{width:200px;border-radius:3px}@media (max-width: 500px){#team h2{margin-left:0}}

#impressum{display:flex;flex-flow:row wrap;align-items:flex-start;justify-content:space-around}#impressum div{text-align:left;max-width:500px;line-height:1.6;padding:0 5%}

#datenschutz{overflow:hidden;text-overflow:ellipsis;margin-top:20px;text-align:left;padding:0 5%}

nav{position:fixed;position:-webkit-sticky;position:sticky;top:0;background-color:white;display:flex;align-items:center;justify-content:space-between;max-width:1200px;width:100%;padding:0 20px;z-index:3000}nav img.mobile-only{display:none}nav header a{color:inherit;text-decoration:none;display:flex;align-items:center;max-width:300px}nav header a:hover{font-weight:bolder}nav header h2{margin-left:5px;max-width:300px;font-size:1.2em;text-align:left}nav ul{display:flex;flex-flow:row nowrap;align-items:center;justify-content:space-between}nav ul li{list-style-type:none;padding:10px 5px}nav ul li a{color:inherit;text-decoration:none}nav ul li:hover{font-weight:bold}#mobile-nav{display:none;position:fixed;left:0;top:77px;z-index:2000;transform:translateY(-275px);transition:0.4s;margin-bottom:-275px}@media (max-width: 700px){nav img.mobile-only{display:inherit}nav ul{display:none}#mobile-nav{visibility:hidden;display:inherit;padding:5px 0}#mobile-nav ul{width:100%;display:flex;flex-flow:column nowrap;align-items:center;justify-content:center;padding:0}#mobile-nav.visible{visibility:visible;transition:0.4s ease;transform:translateY(1px)}}nav li.is-current{font-weight:bolder}@supports (-ms-ime-align: auto){nav{position:fixed}}

footer{background:url(/static/media/footer_background.47b31ca6.svg) no-repeat center;position:relative;background-size:cover;background-position:center top;bottom:-20px;width:100%;height:180px;background-color:#e5e5e5;display:flex;flex-flow:row nowrap;align-items:flex-end;justify-content:space-between;padding-bottom:20px;padding-left:20px;padding-right:20px;margin:0}footer ul{list-style-type:none;display:flex;flex-flow:row nowrap;align-items:flex-end;justify-content:space-between;padding:0;margin:0}footer li{padding:0px 0px 0px 20px}footer li>a{color:inherit;text-decoration:none}footer li>a:hover{font-weight:bold}@media (max-width: 500px){footer{flex-flow:column-reverse nowrap;align-items:flex-end;justify-content:flex-start;background-position:right top;min-height:180px}footer ul{flex-flow:column-reverse nowrap;align-items:space-between;line-height:0.6}footer ul li{margin-bottom:20px}}

