.gallerie {
  height: 350px;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-image: url('../graphics/gallery_background.svg');
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;

  .wrapper {
    width: 100%;
    height: 270px;
  }
  margin-bottom: 20px;
}

.panel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 450px;
  height: 270px;

  img {
    border-radius: 10px;
    width: 450px;
    height: 270px;
    object-fit: cover;
  }
}

@media (max-width: 500px) {
  .panel {
    img {
      border-radius: 3px;
    }
  }
}

@supports (-ms-ime-align: auto) {
  .gallerie {
    margin-top: 50px;
  }
}
