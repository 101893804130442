footer {
  background: url('../graphics/footer_background.svg') no-repeat center;
  position: relative;
  background-size: cover;
  background-position: center top;
  bottom: -20px;
  width: 100%;
  height: 180px;
  background-color: #e5e5e5;

  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: space-between;

  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0;

  ul {
    list-style-type: none;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0;
    margin: 0;
  }

  li {
    padding: 0px 0px 0px 20px;
  }

  li > a {
    color: inherit;
    text-decoration: none;
  }

  li > a:hover {
    font-weight: bold;
  }
}

@media (max-width: 500px) {
  footer {
    flex-flow: column-reverse nowrap;
    align-items: flex-end;
    justify-content: flex-start;

    background-position: right top;

    min-height: 180px;

    ul {
      flex-flow: column-reverse nowrap;
      align-items: space-between;
      line-height: 0.6;

      li {
        margin-bottom: 20px;
      }
    }
  }
}
