.App {
  margin: auto;
  text-align: center;
  background-color: white;
  box-shadow: 1px 1px 50px rgb(192, 192, 192);
  max-width: 1200px;
}

.leaflet-container {
  width: 400px;
  height: 350px;
}

.white-bg-footer {
  background-color: white !important;
}

#home::before {
  display: block;
  content: ' ';
  margin-top: -285px;
  height: 285px;
  visibility: hidden;
  pointer-events: none;
}
