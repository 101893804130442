#krankenkassen {
  width: 100%;
  height: 100%;
  min-height: 430px;
  background-image: url('../graphics/krankenkassen_background.svg');
  background-size: cover;
  background-position: top left;
  background-repeat: no-repeat;

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
  padding: 0 5%;
  padding-top: 80px;

  div {
    width: 400px;
    min-height: 350px;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;

    p {
      padding: 0 5%;
      text-align: left;
    }

    ul {
      list-style-type: none;
      text-align: left;

      li {
        padding-bottom: 1em;
        line-height: 1.5em;
      }
    }
  }

  div.right {
    padding-top: 30px;
  }
}
