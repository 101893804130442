#team {
  width: 100%;
  height: 100%;
  min-height: 600px;
  background-image: url('../graphics/team_background.svg');
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding: 0 5%;
  padding-top: 80px;
  margin: 0;
  position: relative;
  top: 20px;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;

  h2 {
    margin-left: 5%;
  }

  div {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-around;
    width: 100%;

    div {
      max-width: 400px;
      padding: 30px 50px;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: flex-start;
      height: 300px;

      h3 {
        margin: 10px 0;
      }

      p {
        margin: 0;
      }
    }
  }
}

#team {
  img {
    width: 200px;
    border-radius: 3px;
  }
}

@media (max-width: 500px) {
  #team h2 {
    margin-left: 0;
  }
}
