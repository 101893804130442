#impressum {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-around;

  div {
    text-align: left;
    max-width: 500px;
    line-height: 1.6;
    padding: 0 5%;
  }
}
