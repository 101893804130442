#text {
  padding-bottom: 40px;
  h1 {
    margin: 0 auto 20px auto;
  }
  p {
    font-size: 1.2rem;
    max-width: 750px;
    margin: 0 auto;
    text-align: center;
  }
}

@media (max-width: 900px) {
  #text {
    p {
      margin: 0 10%;
    }
  }
}

@media (max-width: 500px) {
  #text {
    h1 {
      margin: 0 10% 20px 10%;
    }

    p {
      font-size: 1rem;
      margin: 0 10%;
      text-align: left;
    }
  }
}
