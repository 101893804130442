#termine {
  width: 100%;
  height: 100%;
  min-height: 380px;

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;

  padding: 0 5%;
  padding-top: 80px;

  div {
    width: 400px;
    height: 320px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    h2 {
      width: 100%;
      text-align: left;
    }

    tbody th {
      font-weight: bold;
      text-align: left;
    }

    p {
      width: 300px;
      font-size: 1.1rem;
      text-align: left;
      padding-top: 20px;
    }
  }
}

@media (max-width: 500px) {
  #termine {
    div {
      p {
        width: 100%;
        padding: 0 5%;
      }
    }
  }
}
