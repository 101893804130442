#kontakt {
  width: 100%;
  height: 100%;
  min-height: 480px;
  background-image: url('../graphics/contact_background.svg');
  background-size: cover;
  background-position: top right;
  background-repeat: no-repeat;
  padding: 0 5%;
  padding-top: 80px;

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;

  div.kontakt-info {
    width: 400px;
    height: 250px;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;

    .adress-info {
      width: 100%;
      height: 100px;
      display: flex;
      align-items: flex-start;
      padding: 0 5%;
      .adresse {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        align-items: flex-start;
        height: 100%;
        padding: 10px 5px;
      }
    }

    .phone-number {
      width: 100%;
      height: 100px;
      display: flex;
      align-items: flex-start;
      padding: 0 5%;
      span {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        align-items: flex-start;
        height: 100%;
        padding: 10px 5px;
      }
    }
  }

  div.karte {
    box-sizing: content-box;
    border-radius: 5px;
    border: 5px solid white;
    width: 399px;
    height: 348px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 10px rgb(70, 70, 70);
  }
}

.kontakt-info img {
  padding: 0 5px;
}
