nav {
  position: fixed;
  position: sticky;
  top: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  padding: 0 20px;
  z-index: 3000;

  img.mobile-only {
    display: none;
  }

  header {
    a {
      color: inherit;
      text-decoration: none;
      display: flex;
      align-items: center;
      max-width: 300px;
    }

    a:hover {
      font-weight: bolder;
    }

    h2 {
      margin-left: 5px;
      max-width: 300px;
      font-size: 1.2em;
      text-align: left;
    }
  }

  ul {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    li {
      list-style-type: none;
      padding: 10px 5px;

      a {
        color: inherit;
        text-decoration: none;
      }
    }

    li:hover {
      font-weight: bold;
    }
  }
}

#mobile-nav {
  display: none;
  position: fixed;
  left: 0;
  top: 77px;
  z-index: 2000;
  transform: translateY(-275px);
  transition: 0.4s;
  margin-bottom: -275px;
}

@media (max-width: 700px) {
  nav {
    img.mobile-only {
      display: inherit;
    }

    ul {
      display: none;
    }
  }

  #mobile-nav {
    visibility: hidden;
    display: inherit;
    ul {
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      padding: 0;
    }
    padding: 5px 0;
  }

  #mobile-nav.visible {
    visibility: visible;
    transition: 0.4s ease;
    transform: translateY(1px);
  }
}

nav li.is-current {
  font-weight: bolder;
}

@supports (-ms-ime-align: auto) {
  nav {
    position: fixed;
  }
}
